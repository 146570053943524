<template>
  <aw-tabler :table="tableJSON">
    <template #filter>
      <el-form
        :model="tableJSON.filter"
        inline
        label-suffix="："
      >
        <el-form-item label="订单编号" prop="trade_no">
          <el-input v-model="tableJSON.filter.trade_no" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="支付时间" prop="addtime">
          <el-date-picker
            v-model="tableJSON.filter.addtime"
            type="datetimerange"
            format="YYYY-MM-DD HH:mm:ss"
            value-format="x"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            style="width: 220px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="tableJSON.filter.type" placeholder="请选择">
            <el-option
              v-for="opt in filterTypeOpt"
              :key="opt.value"
              :label="opt.label"
              :value="opt.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </aw-tabler>
</template>

<script setup>
import {ref, reactive, nextTick} from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {http, tool} from "@/assets/js"
import {useRoute} from 'vue-router'
import {ElMessage} from 'element-plus'
import AwTabler from "@/components/table/aw-tabler.vue";

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const filterTypeOpt = ref([])
const getTypeOpt = () => {
  http.get(`/admin/cashflow/getTypeList?model=cashflow`).then(res => {
    if(res) {
      Object.keys(res).forEach(key => {
        filterTypeOpt.value.push({
          label: res[key],
          value: key
        })
      })
    }
  })
}
getTypeOpt()

const tableJSON = ref({
  title: '平台账单',
  loading: false,
  filter: {
    trade_no: '',
    addtime: [],
    type: '',
  },
  datas: [],
  columns: [
    ...tableColumn([
      {
        title: '订单编号',
        key: 'trade_no',
        minWidth: '100px'
      },
      {
        title: '支付时间',
        key: 'addtime',
        minWidth: '160px'
      },
      {
        title: '类型',
        key: 'type_name',
        minWidth: '100px'
      },
      {
        title: '用户昵称/代理商',
        key: 'nickname',
        minWidth: '180px'
      },
      // {
      //   title: '代理商手机号',
      //   key: 'username',
      //   minWidth: '120px'
      // },
      {
        title: '用户ID/代理商',
        key: 'uid',
        minWidth: '120px'
      },
      {
        title: '收支类型',
        key: 'is_in_name',
        minWidth: '100px'
      },
      {
        title: '实付金额',
        key: 'price',
        minWidth: '100px'
      },
      {
        title: '支付方式',
        key: 'paytype_name',
        minWidth: '100px'
      }
    ]),
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    if(powerlimits.includes('ptzdmx_lb')) {
      tableJSON.value.loading = true
      let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
      const addtime = tableJSON.value.filter.addtime ? tableJSON.value.filter.addtime.map(i => i/1000).join(' ~ ') : ''
      http.post('/admin/cashflow/plat', {
        ...params, ...route.query, ...page, addtime
      }).then(result => {
        tableJSON.value.datas = result.data
        tableJSON.value.page.total = result.total || Infinity
        tableJSON.value.page.page = result.current_page
        tableJSON.value.page.page_size = result.per_page
      }).finally(() => {
        tableJSON.value.loading = false
      })
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
  },
  extras: [
    {
      name: '导出',
      props: { type: 'warning', plain: true, },
      show: () => powerlimits.includes('ptzdmx_dc'),
      action: () => {
        const page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
        const addtime = tableJSON.value.filter.addtime.map(i => i/1000).join(' ~ ')
        http.post('/admin/cashflow/plat', {
          ...tableJSON.value.filter,
          ...route.query,
          ...page,
          addtime,
          export: 1
        }).then(res => {
          if(res.code === 0) ElMessage.success(res.message)
        })
      }
    }
  ],
})
</script>
